<template>
  <!-- Start Containerbar -->
  <div id="containerbar" data-app>
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-6 col-sm-6">
            <h4 class="page-title">{{ $t("remission.remission") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{
                    $t("remission.requisition.home")
                  }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#"> {{ $t("remission.requisition.remission") }} </a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#"> {{ $t(title) }} </a>
                </li>
              </ol>
            </div>
          </div>
          <div
            v-if="withPermissionName(permissions.REM_REQ_ADD_01)"
            class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button"
          >
            <button
              class="btn btn-outline-success"
              v-if="showAction(1)"
              @click="changeAction(2)"
            >
              <i class="feather icon-plus mr-2 pu-5"></i>
              {{ $t("remission.requisition.add_order") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="feather icon-file mr-2"></i>{{ $t(title) }}
                    </h5>
                  </div>
                </div>
              </div>
              <TableComponent
                v-show="showAction(1)"
                ref="tableRequisition"
                :headers="requisitionHeaders"
                :isButton="true"
                @excelTable="excelTable"
                :isUpdate="withPermissionName(permissions.REM_REQ_EDIT_02)"
                @updateRecord="edit"
              />
              
              <div v-show="showAction(2)" class="card-body">
                <AddRequisitionGov
                  ref="addRequisitionGov"
                  :idBranchOffice="branchOffice"
                  @changeAction="changeAction"
                  :alert="alert"
                  v-on:requisitionGovUpdate="listAllRequisitionGob"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddRequisitionGov from "@/components/remission/AddRequisitionGov.vue";
import TableComponent from "@/common/table/TableComponent";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";

import { ServicePath, UtilFront, PermissionConstants } from "@/core";
import { ReceptionRequest, ExcelRequest } from "@/core/request";
import { REQUISITION } from "@/core/DataTableHeadersLocale";
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";

export default {
  data() {
    return {
      requisitionHeaders: REQUISITION(),
      user: "",
      action: 1,
      pathReport: ServicePath.REPORT_PRODUCT_ENTRY,
      modal: false,

      originId: this.branchOffice,
      /* (Nuevo) permisos */
      permissions: PermissionConstants,
      remmisionChange: [],
      loading: false,
      permissions: PermissionConstants,
    };
  },
  computed: {
    ...mapState("auth", ["branchOffice", "actionState"]),
    isRemissionSuppliers() {
      return this.$route.path == "/entradas/proveedor";
    },
    currentPath() {
      return this.$route.path;
    },
    title() {
      return this.action == 1
        ? "remission.requisition.requisition"
        : "remission.requisition.add_order";
    },
  },
  methods: {
    withPermissionName(permissions) {
      return UtilFront.withPermissionName(permissions);
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    showAction(action) {
      return this.action == action;
    },
    edit(item) {
      this.action = 2;
      this.$refs.addRequisitionGov.setInfo(item);
    },
    changeAction(action) {
      if (action == 1) {
        this.model = {};
        this.action = action;
        this.$refs.addRequisitionGov.clear();
      } else {
        this.action = action;
      }
    },
    listAllRequisitionGob() {
      let user = UtilFront.getDataUser();
      this.loading = UtilFront.getLoadding();

      ReceptionRequest.listAllRequisitions({
        userBranchOfficeDTO: {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: user.idUser,
        },
      })
        .then((res) => {
          let { success, message, data } = res.data.data;
          this.$refs.tableRequisition.reloadComponent(data);
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportRemmisionsOrder({
        id: 1,
      });
    },
    /* (Modificacion) Se agrego la funcion permisos permisos */
    withPermissionName(permissions) {
      return UtilFront.withPermissionName(permissions);
    },
  },
  created() {
    let infoUser = UtilFront.getDataUser();
    this.user = infoUser;
    this.listAllRequisitionGob();
  },
  components: {
    AddRequisitionGov,
    ValidationObserver,
    TableComponent,
    AlertMessageComponent,
  },
  watch: {
    "$i18n.locale": function () {
      this.requisitionHeaders = REQUISITION();
    },
  },
};
</script>
