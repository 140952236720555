<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="submit">
      <div class="row">
        <div class="col-md-12">
          <h6>
            <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
            {{ $t("remission.requisition.information_remission") }}
          </h6>
        </div>
        <div class="col-md-6">
          <validation-provider
            v-slot="{ errors }"
            name="order"
            rules="required|max:30"
          >
            <v-text-field
              v-model.trim="requisitionKey"
              :counter="30"
              oninput="this.value = this.value.toUpperCase()"
              :maxLength="30"
              :error-messages="errors"
              :label="$t('label.order')"
              required
            ></v-text-field>
          </validation-provider>
        </div>

        <div class="col-md-6">
          <validation-provider
            v-slot="{ errors }"
            name="bidding"
            rules="required|max:100"
          >
            <v-text-field
              v-model.trim="biddingKey"
              :counter="100"
              :maxLength="100"
              oninput="this.value = this.value.toUpperCase()"
              :error-messages="errors"
              :label="$t('label.bidding')"
              required
            ></v-text-field>
          </validation-provider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <button
            class="btn btn-outline-success"
            type="submit"
            :disabled="invalid"
          >
            <i class="feather icon-save mr-2"></i>
            {{ idRequisition != "" ? "Modificar pedido" : "Registrar pedido" }}
          </button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { UtilFront } from "@/core";
import { ReceptionRequest } from "@/core/request";
import { SwalConfirm } from "@/core/SwalAlert";

export default {
  props: {
    idBranchOffice: {
      required: true,
    },
    changeAction: Function,
    alert: Function,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    dialog: false,
    requisitionKey: "",
    biddingKey: "",
    idRequisition: "",
    user: {},
    loading: null,
    sending: false,
  }),
  created() {
    let infoUser = UtilFront.getDataUser();
    this.user = infoUser;
  },
  methods: {
    setInfo(item) {
      this.requisitionKey = item.requisitionKey;
      this.biddingKey = item.biddingKey;
      this.idRequisition = item.idRequisition;
    },
    submit() {
      this.idRequisition != "" ? this.edit() : this.add();
    },
    async add() {
      const { isConfirmed } = await SwalConfirm.fire({
        text: "La información del pedido será registrada.",
      });
      if (!isConfirmed) {
        return;
      }
      this.$refs.observer.validate;
      //Muestro la pantalla de carga
      this.loading = UtilFront.getLoadding();
      ReceptionRequest.addGovRequesition({
        requisitionKey: this.requisitionKey,
        biddingKey: this.biddingKey,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.idBranchOffice.idBranchoffice,
        },
      })
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.updateRequisitonGov();
            this.alert("success", "Add_Success_0001");
            this.$emit("changeAction", 1);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async edit() {
      const { isConfirmed } = await SwalConfirm.fire({
        text: "La información del pedido será modificada.",
      });
      if (!isConfirmed) {
        return;
      }
      this.$refs.observer.validate;
      let data = {
        requisitionKey: this.requisitionKey,
        biddingKey: this.biddingKey,
        idRequisition: this.idRequisition,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.idBranchOffice.idBranchoffice,
        },
        status: 1,
      };
      //Muestro la pantalla de carga
      this.loading = UtilFront.getLoadding();
      ReceptionRequest.editGovRequesition(data)
        .then((response) => {
          let { message, success } = response.data.data;
          if (success) {
            this.updateRequisitonGov();
            this.alert("success", "Update_Success_0001");
            this.$emit("changeAction", 1);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    updateRequisitonGov() {
      this.$emit("requisitionGovUpdate");
      this.clear();
      this.dialog = false;
    },
    clear() {
      this.requisitionKey = "";
      this.biddingKey = "";
      this.idRequisition = "";
      this.$refs.observer.reset();
    },
  },
};
</script>
